import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Root from "../pages/root/Root";
import Test from "../pages/Test";

const App: React.FC = () => {
  

  return (
    <Router>
      <Routes>
        {/* <Route path="/test" element={<Test />} /> */}
        <Route path="/" element={<Root />} />
        <Route path="*" element={<Root />} />
        </Routes>
    </Router>
  );
};

export default App;
