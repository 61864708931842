import React, { useState } from 'react';
import './Collapsible.css';

interface CollapsibleProps {
  title: string;
  children: React.ReactNode;
}


const Collapsible: React.FC<CollapsibleProps> = ({ title, children }) => {
  const [isActive, setIsActive] = useState(false);


  const handleToggle = () => {
    setIsActive(!isActive);
  };

  return (
    <div className='collapsible-div'>
      <button className={`collapsible ${isActive ? 'active' : ''}`} onClick={handleToggle}>
        {title}
      </button>
      <div
        className="collapsible-content"
        style={{ fontSize:"1.25rem",fontWeight:"400", maxHeight: isActive ? '500px' : '0px' }}
      >
        {children}
      </div>
    </div>
  );
};

export default Collapsible;
