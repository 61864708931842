import React, { ReactNode } from "react";
import "./Sticky.css";

interface StickyComponentProps {
  children: ReactNode;
}

const Sticky: React.FC<StickyComponentProps> = ({ children }) => {
  return (
    <div className="sticky">
      {children}
    </div>
  );
};

export default Sticky;