import React from 'react'
import Sticky from '../../components/sticky/Sticky';
import './Root.css';
import Collapsible from '../../components/collapsible/Collapsible';
import Slider from 'react-slick';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from '../../components/footer/Footer';


const nameData = [
    { _id: '9', nameImage: '/director.png', name: 'Subhash Keshri', nameField: 'Director' },
    { _id: '1', nameImage: '/subh.png', name: 'Shubhanit Keshri', nameField: 'CEO' },
    { _id: '2', nameImage: '/sudhanshu.png', name: 'Sudhanshu Tripathi', nameField: 'Front-End Developer' },
    { _id: '3', nameImage: '/amar.png', name: 'Amar Deep', nameField: 'Back-End Developer' },
    { _id: '4', nameImage: '/sujith.png', name: 'Sujith Kumar Y', nameField: 'Back-End Developer' },
    { _id: '5', nameImage: '/harsh.png', name: 'Harsh', nameField: 'Manager' },
    { _id: '6', nameImage: '/hr.png', name: 'Aashi Keshri', nameField: 'HR' },
    { _id: '7', nameImage: '/akanksha.png', name: 'Akanksha Keshri', nameField: 'Graphic Designer' },
    { _id: '8', nameImage: '/abhay.png', name: 'Abhay Kumar', nameField: 'UIX Designer' },
];

const Root: React.FC = () => {

    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }

    return (
        <div>
            <Sticky>
               <div className="header-content">
                    <div className="header-title">
                        <h1>Rijuvalika Technologies Pvt Ltd</h1>
                    </div>
                    <img className="header-img" src="/rt_sq.png" alt="logo" />
               </div>
            </Sticky>
            <div className="body-content">
                <div className="row">
                   
                    <div className="col-xs-12 col-sm-12 col-12 col-md-8 col-lg-8 col-xl-8">
                        <div className="row">
                            <div className="col-12">
                                <div className="card card-transparent">
                                    <div className="card-body">
                                        <h3 className="card-title">ABOUT US</h3>

                                        <Collapsible title="Vision">
                                        At Rijuvalika Technologies Private Limited, our vision is to create a global impact by revolutionizing mental
                                        health support for teenagers through innovative and accessible solutions. We strive to be pioneers in
                                        leveraging technology to address the rising concerns of depression, anxiety, and suicide among adolescents.
                                        Our vision is clear to provide a comprehensive SaaS platform that empowers individuals with a tailored set of
                                        weekly activities, monthly webinars, half-yearly counseling sessions, yearly physical seminars, self-analysis
                                        tools, and an anonymous peer group. By fostering holistic mental well-being, we aim to contribute significantly
                                        to the overall betterment of society.
                                        </Collapsible>
                                        <Collapsible title="Mission">
                                        At the core of our mission is a commitment to transparency and accountability. We believe in equipping users with the tools they need to actively monitor and improve their mental health. Through a progress-tracking panel, we empower students to track their development and take charge of their well-being. Our unwavering dedication to addressing the root causes of mental health issues reflects our conviction that a multifaceted approach is essential for lasting impact. Rijuvalika Technologies is dedicated to creating a future where every teenager can thrive emotionally and mentally, supported by cutting-edge technology and a compassionate community.
                                        </Collapsible>
                                        
                                    </div>
                                </div>
                                
                                
                            </div>
                            <div className="col-12">
                                <div className="card card-transparent">
                                    <div className="card-body">
                                        <h3 className="card-title">WHAT WE DO</h3>

                                        <Collapsible title="Comprehensive SaaS Platform">
                                            Rijuvalika Technologies offers a state-of-the-art Software as a Service (SaaS) platform focused on improving the mental well-being of teenagers. Our platform serves as a holistic solution addressing prevalent issues such as depression, anxiety, and suicidal tendencies.
                                        </Collapsible>
                                        <Collapsible title="Tailored Services">
                                            We provide a meticulously crafted package, encompassing  weekly activities, monthly  insightful webinars, half yearly  personalized counseling sessions, yearly  physical seminar, and self-analysis tools. Each component is designed to cater to the unique needs of our users, promoting a well-rounded approach to mental health improvement.
                                        </Collapsible>
                                        <Collapsible title="Technologically Advanced Approach">
                                            Rijuvalika Technologies integrates cutting-edge technology to deliver contemporary mental health solutions. By leveraging SaaS, we ensure accessibility, scalability, and effectiveness, positioning our company at the forefront of addressing mental health challenges among teenagers.
                                        </Collapsible>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="card card-transparent">
                                    <div className="card-body">
                                        <h3 className="card-title">WHY US</h3>

                                        <Collapsible title="Tailored Combination of Services">
                                            Our USP lies in offering a uniquely tailored combination of  weekly activities, webinars, counseling sessions, physical seminars, self-analysis tools, and an anonymous peer group, providing a holistic and personalized approach to teenage mental health.
                                        </Collapsible>
                                        <Collapsible title="Integrated Progress Tracking">
                                            Distinguishing ourselves from competitors, our platform features an advanced progress-tracking panel, empowering users to actively monitor and assess their mental health development, fostering a sense of accountability and control.
                                        </Collapsible>
                                        <Collapsible title="Focus on Online Security">
                                            Rijuvalika Technologies prioritizes online security, implementing robust encryption and authentication measures to create a secure environment. This focus on safety sets us apart, addressing the increasing risk of fraudulent activities in the digital space.
                                        </Collapsible>
                                        <Collapsible title="Global Accessibility and Inclusivity">
                                        We stand out by offering our platform globally, breaking down geographical barriers and ensuring accessibility for teenagers worldwide. This inclusivity is a key differentiator, reaching those who may be isolated or lack local mental health resources.
                                        </Collapsible>
                                        <Collapsible title="Comprehensive and Technology-Driven Approach">
                                        Our SaaS-based platform reflects a contemporary and technologically advanced solution to teenage mental health issues. The integration of cutting-edge technology ensures scalability, effectiveness, and a forward-looking approach, setting us apart in the market.
                                        </Collapsible>
                                    </div>
                                </div>
                            </div>
                             
                        </div>

                    </div>
                    <div className="col-xs-12 col-sm-12 col-12 col-md-4 col-lg-4 col-xl-4" style={{display:"flex",alignItems:"center"}}>
                        <div className="card card-transparent">
                            <div className="card-body">
                                <h3 className="card-title">OUR SERVICE</h3>

                                <Collapsible title="Comprehensive Mental Health Platform">
                                    Rijuvalika Technologies offers a comprehensive Software as a Service (SaaS) platform designed to address the mental health needs of teenagers aged 13 to 25.
                                </Collapsible>
                                <Collapsible title="Tailored Support Packages">
                                    Our service includes a carefully curated package featuring  weekly activities, monthely webinars, half yearly counseling sessions, yearly physical seminar, self-analysis tools, and an anonymous peer group, providing a holistic approach to mental well-being.
                                </Collapsible>
                                <Collapsible title="Progress Tracking Panel">
                                    Users benefit from an integrated progress-tracking panel, empowering them to actively monitor and assess their mental health development, promoting transparency and accountability in their well-being journey
                                </Collapsible>
                                <Collapsible title="Global Accessibility">
                                Our platform ensures global accessibility, breaking down geographical barriers and reaching teenagers worldwide. This inclusivity is vital in catering to diverse cultural and societal contexts.
                                </Collapsible>
                                <Collapsible title="Cutting-Edge Technology">
                                Rijuvalika Technologies leverages cutting-edge technology, offering a contemporary solution to teenage mental health challenges. The integration of SaaS ensures scalability, efficiency, and a forward-looking approach in delivering impactful mental health services.
                                </Collapsible>
                            </div>
                        </div>
                                

                    </div>
                   
                </div>

                <div className="row" style={{justifyContent:"center"}}>
                    <div className="card card-transparent" style={{width:"95%"}}>
                        <div className="card-body">
                            <br />
                            <h2 className="card-title" style={{display:"flex",justifyContent:"center"}}>Team Members</h2>
                            <br />
                            <Slider {...sliderSettings}>
                                {nameData.map((counsellor) => (
                                    <div key={counsellor._id}>
                                        <div className='slick-div'>
                                            <img src={counsellor.nameImage} alt={counsellor.name} style={{ width: '240px', height: '240px', borderRadius: '50%', objectFit: 'cover' }} />
                                        </div>
                                        <br />
                                        <h3 className='slick-name'>{counsellor.name}</h3>
                                        <p className='slick-designation'>{counsellor.nameField}</p>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                </div>
                <br />
                <br />
                <br />



                <Footer/>




                
            </div>
           

           
        </div>
  );
}

export default Root